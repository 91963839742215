import { create } from "zustand";

export const useAppStore = create((set) => ({
    darkMode: 'light',

    loadDarkMode: () => set((state) => {
        let newDarkMode = window.localStorage.getItem('darkMode') ? window.localStorage.getItem('darkMode') : 'light';
        window.document.documentElement.setAttribute('data-bs-theme', newDarkMode);
        return { darkMode: newDarkMode };
    }),
    setDarkMode: (newDarkMode) => set((state) => {
        window.localStorage.setItem('darkMode', newDarkMode);
        window.document.documentElement.setAttribute('data-bs-theme', newDarkMode);
        return { darkMode: newDarkMode };
    }),
}));