import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logo from "./favicon192.png";
import stone from "./stone.png";
import elixir from "./elixir.png";
import cho from "./cho.png";
import discord from "./discord-mark-blue.png";
import { useEffect } from "react";
import { useAppStore } from "./AppStore";
import { Footer } from "./modules/Footer";

function App() {
  useEffect(() => {
    useAppStore.getState().loadDarkMode();
  }, []);
  const setDarkMode = useAppStore((state) => state.setDarkMode);
  const darkMode = useAppStore((state) => state.darkMode);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          paddingBottom: "120px",
        }}
      >
        <Navbar className="mb-3 bg-primary">
          <Container>
            <Navbar.Brand href=".">
              <img
                src={logo}
                width="30"
                className="d-inline-block align-top"
                style={{ backgroundColor: "white", borderRadius: "30%" }}
                alt="엘파고"
              />
            </Navbar.Brand>
            <Nav variant="underline" className="me-auto">
              <Nav.Link
                className="text-white"
                href="https://elixir2.elphago.work/"
              >
                엘릭서V2
              </Nav.Link>
              <Nav.Link
                className="text-white"
                href="https://stone.elphago.work/"
              >
                영상인식 돌파고
              </Nav.Link>
              <Nav.Link className="text-white" href="https://cho.elphago.work/">
                초월
              </Nav.Link>
              <Nav.Link
                className="text-white"
                href="https://discord.gg/Y64NBK2NEc"
              >
                Discord
              </Nav.Link>
            </Nav>

            <div className="nav-check">
              <Form.Check
                className="text-white"
                type="switch"
                id="darkModeSwitch"
                label="다크모드"
                onChange={(e) => {
                  setDarkMode(e.target.checked ? "dark" : "light");
                }}
                checked={darkMode === "dark"}
              />
            </div>
          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col>
              <Card className="mx-auto" style={{ width: "20rem" }}>
                <Card.Img variant="top" src={elixir}></Card.Img>
                <Card.Body>
                  <Card.Title>엘파고V2-엘릭서 조언</Card.Title>
                  <a
                    href="https://elixir2.elphago.work/"
                    className="stretched-link"
                  >
                    {" "}
                  </a>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mx-auto" style={{ width: "20rem" }}>
                <Card.Img variant="top" src={stone}></Card.Img>
                <Card.Body>
                  <Card.Title>영상인식 돌파고</Card.Title>
                  <a
                    href="https://stone.elphago.work/"
                    className="stretched-link"
                  >
                    {" "}
                  </a>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mx-auto" style={{ width: "20rem" }}>
                <Card.Img variant="top" src={cho}></Card.Img>
                <Card.Body>
                  <Card.Title>초파고-초월 3성 달성 조언</Card.Title>
                  <a
                    href="https://cho.elphago.work/"
                    className="stretched-link"
                  >
                    {" "}
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mx-auto" style={{ width: "20rem" }}>
                <Card.Img variant="top" src={discord}></Card.Img>
                <Card.Body>
                  <Card.Title>Discord</Card.Title>
                  <a
                    href="https://discord.gg/Y64NBK2NEc"
                    className="stretched-link"
                  >
                    {" "}
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          position: "relative",
          top: "-120px",
        }}
      >
        <Footer />
      </div>
    </>
  );
}

export default App;
